(function (){
    function businessCover() {
        var b = document.querySelector('.business-profile');
        if (!b) return;

        var img_path = 'global/resources/images/_site/_marketplace/header-' + b.dataset.id;
        var covers = [
            img_path + '.png',
            img_path + '.jpg',
            img_path + '.gif'
        ];
        for (var i = 0; i < covers.length; i++) {
            var tmpCover = new Image();
            tmpCover.onload = function(){
                document.querySelector('.business-cover-color').style.display = 'none';
                document.querySelector('.business-cover').style.backgroundImage = 'url(' + this.src + ')';
                var streetview_el = document.querySelector('.business-cover .streetview');
                if (streetview_el) streetview_el.style.display = 'none';
                return false;
            };
            tmpCover.src = covers[i];
        }
    }

    if (document.readyState == 'loading') {
        document.addEventListener('DOMContentLoaded', function() {
            businessCover();
        });
    } else {
        businessCover();
    }
})();